
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/4d457fdfae4a3386/packages/challenges-web-ooi/src/components/PaymentPage/Widget/index.ts';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://2a7edf28dde8465da7868bdd5f056f7a@sentry.wixpress.com/387',
      id: '2a7edf28dde8465da7868bdd5f056f7a',
      projectName: 'challenges-ooi-app',
      teamName: 'challenges-ooi',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'PaymentPage',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
